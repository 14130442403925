import React, { useState, useEffect, useMemo } from "react";
import EncaissementService from "../Services/Encaisement";
import DecaissementService from "../Services/Decaisement";
import ExcelTable from "../Components/TableExcel/VaultTable";
import {
  Box,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Card,
  CardContent,
  Pagination,
} from "@mui/material";

const CaisseTransactions = () => {
  const [encaissements, setEncaissements] = useState([]);
  const [decaissements, setDecaissements] = useState([]);
  const [dateDebut, setDateDebut] = useState("");
  const [dateFin, setDateFin] = useState("");
  const [filterType, setFilterType] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const restaurantId = localStorage.getItem("restaurantId");
  const rowsPerPage = 30;

  useEffect(() => {
    const fetchData = async () => {
      if (!restaurantId) return; // If restaurantId is null, do not fetch data
      
      try {
        const fetchedEncaissements = await EncaissementService.getAllEncaissements();
        const fetchedDecaissements = await DecaissementService.getAllDecaissements();

        const filteredEncaissements = fetchedEncaissements.filter(
          (row) => row.restaurantId === restaurantId
        );
        const filteredDecaissements = fetchedDecaissements.filter(
          (row) => row.restaurantId === restaurantId
        );

        setEncaissements(filteredEncaissements);
        setDecaissements(filteredDecaissements);
      } catch (error) {
        console.error("Error fetching data:", error.message);
      }
    };

    fetchData();
  }, [restaurantId]);

  const combinedData = useMemo(() => {
    if (!restaurantId) return [];
    return [
      ...encaissements.map((row) => ({ ...row, category: "ENCAISSEMENT" })),
      ...decaissements.map((row) => ({ ...row, category: "DECAISSEMENT" })),
    ];
  }, [encaissements, decaissements, restaurantId]);

  useEffect(() => {
    if (!restaurantId) return;

    const filtered = combinedData.filter((row) => {
      const transactionDate = new Date(row.date);
      const startDate = new Date(dateDebut);
      const endDate = new Date(dateFin);

      const matchesDateRange =
        (!dateDebut || transactionDate >= startDate) &&
        (!dateFin || transactionDate <= endDate);
      const matchesType = filterType ? row.category === filterType : true;

      return matchesDateRange && matchesType;
    });

    setFilteredData(filtered);
    setCurrentPage(1);
  }, [dateDebut, dateFin, filterType, combinedData, restaurantId]);

  const paginatedData = useMemo(() => {
    const startIndex = (currentPage - 1) * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    return filteredData.slice(startIndex, endIndex);
  }, [filteredData, currentPage, rowsPerPage]);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  return (
    <div style={{ padding: "20px", backgroundColor: "#f4f6f9" }}>
      <Box
        sx={{
          marginBottom: 3,
          padding: 3,
          backgroundColor: "#90caf9",
          borderRadius: 3,
          boxShadow: 3,
        }}
      >
        <Typography
          variant="h4"
          sx={{ fontWeight: 700, color: "#0d47a1", textAlign: "center" }}
        >
          Caisse Table
        </Typography>
      </Box>

      {!restaurantId ? (
        <Typography variant="h5" sx={{ textAlign: "center", color: "red" }}>
          Aucune donnée disponible. Veuillez sélectionner un restaurant.
        </Typography>
      ) : (
        <>
          <Box
            sx={{
              display: "flex",
              gap: 2,
              alignItems: "center",
              marginBottom: 3,
              flexWrap: "wrap",
              justifyContent: "center",
            }}
          >
            <TextField
              label="Date Début"
              type="date"
              value={dateDebut}
              onChange={(e) => setDateDebut(e.target.value)}
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              sx={{ minWidth: 200 }}
            />
            <TextField
              label="Date Fin"
              type="date"
              value={dateFin}
              onChange={(e) => setDateFin(e.target.value)}
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              sx={{ minWidth: 200 }}
            />
            <FormControl variant="outlined" sx={{ minWidth: 200 }}>
              <InputLabel>Type</InputLabel>
              <Select
                value={filterType}
                onChange={(e) => setFilterType(e.target.value)}
                label="Type"
              >
                <MenuItem value="">Tous types</MenuItem>
                <MenuItem value="ENCAISSEMENT">Encaissement</MenuItem>
                <MenuItem value="DECAISSEMENT">Decaissement</MenuItem>
              </Select>
            </FormControl>
          </Box>

          <Card sx={{ marginBottom: 3, boxShadow: 2, borderRadius: 3 }}>
            <CardContent>
              <div style={{ overflowX: "auto" }}>
                <ExcelTable data={paginatedData} />
              </div>
            </CardContent>
          </Card>

          <Box sx={{ display: "flex", justifyContent: "center", marginTop: 3 }}>
            <Pagination
              count={Math.ceil(filteredData.length / rowsPerPage)}
              page={currentPage}
              onChange={handlePageChange}
              color="primary"
            />
          </Box>
        </>
      )}
    </div>
  );
};

export default CaisseTransactions;