import React, { useState, useEffect, useMemo } from "react";
import ExcelTable4 from "../Components/TableExcel/TableExcel";
import Encaissement from "../Services/Encaisement";
import {
  Card,
  CardContent,
  Typography,
  TextField,
  Button,
  Box,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Grid,
  useTheme,
  Tooltip,
  Avatar,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import FilterListIcon from "@mui/icons-material/FilterList";
import DateRangeIcon from "@mui/icons-material/DateRange";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import { green, lightRed, red } from "@mui/material/colors";

const EncaissementsPage = () => {
  const theme = useTheme();
  const [encaissements, setEncaissements] = useState([]);
  const [newEncaissement, setNewEncaissement] = useState({
    value: "",
    type: "",
    date: new Date().toISOString().split("T")[0],
    customType: "",
  });
  const [editingEncaissement, setEditingEncaissement] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [openDialog, setOpenDialog] = useState(false);
  const [existingTypes, setExistingTypes] = useState([]);
  const [searchType, setSearchType] = useState("");
  const [dateDebut, setDateDebut] = useState("");
  const [dateFin, setDateFin] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const restaurantId = localStorage.getItem("restaurantId");

  // Memoized filtered data
  const filteredEncaissements = useMemo(() => {
    return encaissements.filter((encaissement) => {
      const typeMatch = searchType ? encaissement.type === searchType : true;
      const date = new Date(encaissement.date);
      const startDate = dateDebut ? new Date(dateDebut) : null;
      const endDate = dateFin ? new Date(dateFin) : null;
      
      if (startDate) startDate.setUTCHours(0, 0, 0, 0);
      if (endDate) endDate.setUTCHours(23, 59, 59, 999);
      
      const dateMatch = 
        (!startDate || date >= startDate) && 
        (!endDate || date <= endDate);
      
      return typeMatch && dateMatch;
    });
  }, [encaissements, searchType, dateDebut, dateFin]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const fetchedEncaissements = await Encaissement.getEncaissementsByRestaurantId(restaurantId);
        setEncaissements(fetchedEncaissements);
      } catch (error) {
        setError("Failed to load transactions. Please try again later.");
      } finally {
        setLoading(false);
      }
    };
    
    if (restaurantId) fetchData();
  }, [restaurantId]);

  const handleAddEncaissement = async () => {
    const { value, type, date, customType } = newEncaissement;
    const finalType = type === "Other" ? customType : type;
    
    if (!value || !finalType || !date) {
      setError("Please fill in all required fields");
      return;
    }
    
    try {
      const encaissement = new Encaissement(
        parseFloat(value),
        finalType,
        date,
        restaurantId
      );
      
      await encaissement.addEncaissement();
      setEncaissements(prev => [...prev, encaissement]);
      handleCloseDialog();
    } catch (error) {
      setError("Failed to create transaction. Please try again.");
    }
  };

  const handleEditEncaissement = (enc) => {
    const existingTypes = [...new Set(encaissements.map(e => e.type))];
    const isCustomType = !existingTypes.includes(enc.type);
    
    setEditingEncaissement(enc);
    setNewEncaissement({
      value: parseFloat(enc.value),
      type: isCustomType ? "Other" : enc.type,
      date: enc.date,
      customType: isCustomType ? enc.type : "",
    });
    setOpenDialog(true);
  };

  const handleSaveEdit = async () => {
    try {
      const updates = {
        value: parseFloat(newEncaissement.value),
        type: newEncaissement.type === "Other" 
          ? newEncaissement.customType 
          : newEncaissement.type,
        date: newEncaissement.date,
      };
      
      await Encaissement.updateEncaissement(editingEncaissement.id, updates);
      setEncaissements(prev =>
        prev.map(enc => enc.id === editingEncaissement.id ? { ...enc, ...updates } : enc)
      );
      handleCloseDialog();
    } catch (error) {
      setError("Failed to update transaction. Please try again.");
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setEditingEncaissement(null);
    setNewEncaissement({
      value: "",
      type: "",
      date: new Date().toISOString().split("T")[0],
      customType: "",
    });
  };

  const handleDeleteEncaissement = async (id) => {
    try {
      await Encaissement.deleteEncaissement(id);
      setEncaissements((prev) => prev.filter((encaissement) => encaissement.id !== id));
    } catch (error) {
      console.error("Error deleting encaissement:", error.message);
    }
  };

  const handleChangePage = (event, newPage) => setPage(newPage);
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

 

  return (
    <Box sx={{ p: 4, backgroundColor: theme.palette.background.default }}>
            <Card sx={{ 
        mb: 4, 
        background: 'linear-gradient(45deg,rgb(71, 183, 31) 30%,rgb(79, 208, 19) 90%)',
        boxShadow: 3,
        borderRadius: 4
      }}>
        <CardContent>
          <Box sx={{ 
            display: 'flex', 
            alignItems: 'center', 
            gap: 3,
            color: 'common.white'
          }}>
            <Avatar sx={{ 
              bgcolor: 'common.white', 
              width: 56, 
              height: 56 
            }}>
              <AttachMoneyIcon sx={{ color: 'error.main', fontSize: 32 }} />
            </Avatar>
            <Box>
              <Typography variant="h4" sx={{ fontWeight: 700 }}>
                Encaissement Management
              </Typography>
              <Typography variant="body1">
                Track and manage all financial expenditures
              </Typography>
            </Box>
          </Box>
        </CardContent>
      </Card>


 {/* Filters Section */}
 <Card sx={{ mb: 4, borderRadius: 3, boxShadow: 2 }}>
        <CardContent>
          <Grid container spacing={3} sx={{ alignItems: 'center' }}>
            <Grid item xs={12} md={3}>
              
            <FormControl fullWidth variant="filled">
        <InputLabel>Filter by Type</InputLabel>
        <Select value={searchType} onChange={(e) => setSearchType(e.target.value)}>
          <MenuItem value="">All Types</MenuItem>
          {[...new Set(encaissements.map((e) => e.type))].map((type) => (
            <MenuItem key={type} value={type}>
              {type}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
            </Grid>

            <Grid item xs={12} md={3}>
              <TextField
                fullWidth
                variant="filled"
                label="Start Date"
                type="date"
                value={dateDebut}
                onChange={(e) => setDateDebut(e.target.value)}
                InputProps={{
                  startAdornment: <DateRangeIcon sx={{ color: 'action.active', mr: 1 }} />,
                }}
              />
            </Grid>

            <Grid item xs={12} md={3}>
              <TextField
                fullWidth
                variant="filled"
                label="End Date"
                type="date"
                value={dateFin}
                onChange={(e) => setDateFin(e.target.value)}
                InputProps={{
                  startAdornment: <DateRangeIcon sx={{ color: 'action.active', mr: 1 }} />,
                }}
              />
            </Grid>

            <Grid item xs={12} md={3}>
            <Button
              variant="contained"
           
              onClick={() => setOpenDialog(true)}
              sx={{
                height: 50,
                px: 4,
                borderRadius: 3,
                background: 'linear-gradient(45deg, #4CAF50 30%, #45a049 90%)',
                '&:hover': {
                  transform: 'translateY(-2px)',
                  boxShadow: 4
                }
              }}
            >
              New Transaction
            </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

     

      <Card sx={{ 
        mb: 4, 
        borderRadius: 4,
        boxShadow: 3,
        '&:hover': {
          boxShadow: 6
        }
      }}>
        <CardContent>
       
          <div style={{ overflowX: 'auto' }}>
            <ExcelTable4 data={filteredEncaissements} title="Encaissements" />
          </div>
        </CardContent>
      </Card>

      <Card sx={{ borderRadius: 4, boxShadow: 3 }}>
        <TableContainer>
          <Table sx={{ minWidth: 800 }}>
            <TableHead sx={{ backgroundColor: "white" }}>
              <TableRow>
                {['Date', 'Value', 'Type', 'Actions'].map((header, index) => (
                  <TableCell key={index} sx={{
                    fontWeight: 'bold',
                    fontSize: '1rem',
                    color: "black"
                  }}>
                    {header}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredEncaissements
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((enc) => (
                  <TableRow 
                    key={enc.id} 
                    hover 
                    sx={{ 
                      '&:nth-of-type(odd)': { backgroundColor: 'action.hover' },
                      '&:hover': { backgroundColor: 'action.selected' }
                    }}
                  >
                    <TableCell sx={{ fontWeight: 500 }}>{enc.date}</TableCell>
                    <TableCell sx={{ color: "green", fontWeight: 600 }}>
                      {enc.value} TND
                    </TableCell>
                    <TableCell>
                      <Box sx={{ 
                        display: 'inline-block',
                        px: 1.5,
                        py: 0.5,
                        borderRadius: 1,
                        backgroundColor: theme.palette.secondary.light,
                        color: theme.palette.secondary.contrastText
                      }}>
                        {enc.type}
                      </Box>
                    </TableCell>
                    <TableCell>
                      <IconButton 
                        onClick={() => handleEditEncaissement(enc)}
                        sx={{ color: theme.palette.info.main, '&:hover': { bgcolor: 'rgba(33, 150, 243, 0.1)' } }}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton 
                        onClick={() => handleDeleteEncaissement(enc.id)}
                        sx={{ color: theme.palette.error.main, '&:hover': { bgcolor: 'rgba(244, 67, 54, 0.1)' } }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filteredEncaissements.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{ borderTop: `1px solid ${theme.palette.divider}` }}
        />
      </Card>

      <Dialog 
        open={openDialog} 
        onClose={() => setOpenDialog(false)}
        PaperProps={{ sx: { borderRadius: 4, width: '100%', maxWidth: 600 } }}
      >
        <DialogTitle sx={{ 
          backgroundColor: theme.palette.primary.light,
          color: theme.palette.getContrastText(theme.palette.primary.light),
          borderBottom: `2px solid ${theme.palette.primary.main}`
        }}>
          {editingEncaissement ? "Edit Transaction" : "New Transaction"}
        </DialogTitle>
        <DialogContent sx={{ py: 4 }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Amount (TND)"
                type="number"
                variant="outlined"
                value={newEncaissement.value}
                onChange={(e) => setNewEncaissement({ ...newEncaissement, value: e.target.value })}
                InputProps={{
                  startAdornment: <Typography sx={{ mr: 1 }}>TND</Typography>,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>Transaction Type</InputLabel>
                <Select
                  value={newEncaissement.type}
                  onChange={(e) => setNewEncaissement({
                    ...newEncaissement,
                    type: e.target.value,
                    customType: e.target.value === "Other" ? newEncaissement.customType : "",
                  })}
                  label="Transaction Type"
                >
                  <MenuItem value="">Select Type</MenuItem>
                  {[...new Set(encaissements.map((enc) => enc.type))].map((type) => (
                    <MenuItem key={type} value={type}>{type}</MenuItem>
                  ))}
                  <MenuItem value="Other">Other</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {newEncaissement.type === "Other" && (
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Custom Type"
                  variant="outlined"
                  value={newEncaissement.customType}
                  onChange={(e) => setNewEncaissement({ ...newEncaissement, customType: e.target.value })}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Transaction Date"
                type="date"
                variant="outlined"
                value={newEncaissement.date}
                onChange={(e) => setNewEncaissement({ ...newEncaissement, date: e.target.value })}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ px: 4, pb: 3 }}>
          <Button 
            onClick={() => setOpenDialog(false)}
            variant="outlined"
            sx={{ borderRadius: 3, px: 4 }}
          >
            Cancel
          </Button>
          <Button 
            onClick={editingEncaissement ? handleSaveEdit : handleAddEncaissement}
            variant="contained"
            sx={{ 
              borderRadius: 3,
              px: 4,
              background: 'linear-gradient(45deg, #4CAF50 30%, #45a049 90%)'
            }}
          >
            {editingEncaissement ? "Save Changes" : "Create Transaction"}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default EncaissementsPage;
