import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  useTheme,
  useMediaQuery,
  alpha,
  IconButton,
  Box
} from '@mui/material';
import { lightBlue, cyan } from '@mui/material/colors';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';

const ExcelTable4 = ({ data, title }) => {
  const types = data ? [...new Set(data.map(row => row.type))] : [];
  const [currentPage, setCurrentPage] = React.useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { monthsData, initialPage } = React.useMemo(() => {
    if (!data) return { monthsData: [], initialPage: 0 };

    const groupedByMonth = data.reduce((acc, row) => {
      const dateObj = new Date(row.date);
      const monthKey = `${dateObj.getFullYear()}-${String(dateObj.getMonth() + 1).padStart(2, '0')}`;
      const dateKey = row.date;

      if (!acc[monthKey]) {
        acc[monthKey] = {
          monthKey,
          dates: {},
          total: 0
        };
      }

      if (!acc[monthKey].dates[dateKey]) {
        acc[monthKey].dates[dateKey] = {
          date: row.date,
          values: {},
          total: 0
        };
      }

      const type = row.type;
      acc[monthKey].dates[dateKey].values[type] = (acc[monthKey].dates[dateKey].values[type] || 0) + row.value;
      acc[monthKey].dates[dateKey].total += row.value;
      acc[monthKey].total += row.value;

      return acc;
    }, {});

    const monthsData = Object.values(groupedByMonth)
      .sort((a, b) => new Date(b.monthKey) - new Date(a.monthKey))
      .map(month => ({
        ...month,
        dates: Object.values(month.dates).sort((a, b) => new Date(b.date) - new Date(a.date))
      }));

    const currentDate = new Date();
    const currentMonthKey = `${currentDate.getFullYear()}-${String(currentDate.getMonth() + 1).padStart(2, '0')}`;
    const initialPage = monthsData.findIndex(m => m.monthKey === currentMonthKey);

    return { 
      monthsData, 
      initialPage: initialPage !== -1 ? initialPage : 0 
    };
  }, [data]);

  React.useEffect(() => {
    setCurrentPage(initialPage);
  }, [initialPage]);

  const formatMonth = (monthKey) => {
    if (!monthKey) return '';
    const [year, month] = monthKey.split('-');
    const date = new Date(year, month - 1);
    return date.toLocaleDateString('en-US', { month: 'long', year: 'numeric' });
  };

  const handlePreviousPage = () => {
    setCurrentPage(prev => Math.max(prev - 1, 0));
  };

  const handleNextPage = () => {
    setCurrentPage(prev => Math.min(prev + 1, monthsData.length - 1));
  };

  const formatNumber = num => 
    new Intl.NumberFormat('en-US', { 
      style: 'currency', 
      currency: 'TND', 
      minimumFractionDigits: 2 
    }).format(num || 0);

  // Fixed width configuration
  const columnWidths = {
    date: 120,    // Fixed date column width
    type: 150,    // Fixed width for each type column
    total: 120    // Fixed total column width
  };

  // Calculate minimum table width based on columns
  const tableMinWidth = columnWidths.date + 
    (types.length * columnWidths.type) + 
    columnWidths.total;


  
    return (
      <Box sx={{ 
        maxWidth: '100%',
        overflowX: 'auto',
        position: 'relative',
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: 2,
        '&::-webkit-scrollbar': {
          height: 6,
        },
        '&::-webkit-scrollbar-thumb': {
          borderRadius: 3,
          backgroundColor: theme.palette.action.disabled,
        }
      }}>
        <TableContainer 
          component={Paper} 
          sx={{ 
            minWidth: tableMinWidth,
            background: theme.palette.background.paper
          }}
        >
          <Table 
            size="small"
            sx={{
              tableLayout: 'fixed', // Crucial for fixed column widths
              '& .MuiTableCell-root': {
                py: 1.2,
                px: 1.5,
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              }
            }}
          >
            {/* Table Head */}
            <TableHead>
              <TableRow>
                {/* Date Column */}
                <TableCell 
                  align="left"
                  rowSpan={2}
                  sx={{
                    width: columnWidths.date,
                    minWidth: columnWidths.date,
                    maxWidth: columnWidths.date,
                    fontWeight: 700,
                    bgcolor: alpha(theme.palette.primary.main, 0.9),
                    color: 'common.white',
                    position: 'sticky',
                    left: 0,
                    zIndex: 2,
                    borderRight: `1px solid ${alpha(theme.palette.divider, 0.3)}`,
                    backdropFilter: 'blur(6px)'
                  }}
                >
                  Date
                </TableCell>
                
                {/* Header Row */}
               
              </TableRow>
  
              {/* Type Columns Row */}
              <TableRow sx={{ bgcolor: alpha(theme.palette.primary.light, 0.07) }}>
                {types.map((type) => (
                  <TableCell 
                    key={type} 
                    align="center"
                    sx={{
                      width: columnWidths.type,
                      minWidth: columnWidths.type,
                      maxWidth: columnWidths.type,
                      fontWeight: 600,
                      borderRight: `1px solid ${alpha(theme.palette.divider, 0.2)}`
                    }}
                  >
                    {type}
                  </TableCell>
                ))}
                <TableCell 
                  align="center" 
                  sx={{ 
                    width: columnWidths.total,
                    minWidth: columnWidths.total,
                    maxWidth: columnWidths.total,
                    fontWeight: 700,
                    bgcolor: alpha(theme.palette.success.light, 0.15)
                  }}
                >
                  Total
                </TableCell>
              </TableRow>
            </TableHead>
  
            {/* Table Body */}
            <TableBody>
              {monthsData[currentPage]?.dates.map((dateRow) => (
                <TableRow hover key={dateRow.date}>
                  {/* Date Cell */}
                  <TableCell 
                    align="left"
                    sx={{
                      width: columnWidths.date,
                      minWidth: columnWidths.date,
                      maxWidth: columnWidths.date,
                      position: 'sticky',
                      left: 0,
                      zIndex: 1,
                      bgcolor: 'background.paper',
                      borderRight: `1px solid ${alpha(theme.palette.divider, 0.2)}`
                    }}
                  >
                    {new Date(dateRow.date).toLocaleDateString('en-GB')}
                  </TableCell>
  
                  {/* Type Cells */}
                  {types.map((type) => (
                    <TableCell 
                      key={type} 
                      align="right"
                      sx={{
                        width: columnWidths.type,
                        minWidth: columnWidths.type,
                        maxWidth: columnWidths.type,
                        borderRight: `1px solid ${alpha(theme.palette.divider, 0.2)}`
                      }}
                    >
                      {formatNumber(dateRow.values[type] || 0)}
                    </TableCell>
                  ))}
  
                  {/* Total Cell */}
                  <TableCell 
                    align="right"
                    sx={{
                      width: columnWidths.total,
                      minWidth: columnWidths.total,
                      maxWidth: columnWidths.total,
                      fontWeight: 700,
                      bgcolor: alpha(theme.palette.success.light, 0.1)
                    }}
                  >
                    {formatNumber(dateRow.total)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    );
  };
  
  export default React.memo(ExcelTable4);