import React, { useState, useEffect } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { auth, storage, db } from "../Firebase/Config"; 
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { collection, addDoc } from "firebase/firestore";
import {
  TextField,
  Button,
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

const AddRestaurantFormPopup = () => {
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [matriculeFiscale, setMatriculeFiscale] = useState("");
  const [RestoPic, setRestoPic] = useState(null);
  const [RestoPicPreview, setRestoPicPreview] = useState(null);
  const [locals, setLocals] = useState([]);
  const [localInput, setLocalInput] = useState("");
  const [message, setMessage] = useState("");
  const [currentStep, setCurrentStep] = useState(1);
  const [open, setOpen] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  // Check Firebase authentication
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
      setLoading(false);
    });
    return unsubscribe;
  }, []);

  // Handle modal open/close
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // Handle file selection
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setRestoPic(file);
    if (file) {
      setRestoPicPreview(URL.createObjectURL(file));
    }
  };

  // Handle adding local addresses
  const handleAddLocal = () => {
    if (localInput) {
      setLocals([...locals, localInput]);
      setLocalInput("");
    }
  };

  // Upload image to Firebase Storage and return URL
  const uploadImage = async (file) => {
    if (!file) return null;
    const storageRef = ref(storage, `restaurants/${file.name}`);
    await uploadBytes(storageRef, file);
    return getDownloadURL(storageRef);
  };

  // Handle form submission
  const handleAddRestaurant = async (e) => {
    e.preventDefault();
    
    if (!currentUser) {
      setMessage("User not authenticated.");
      return;
    }

    try {
      let imageUrl = "";
      if (RestoPic) {
        imageUrl = await uploadImage(RestoPic);
      }

      // Add restaurant to Firestore
      const docRef = await addDoc(collection(db, "restaurants"), {
        name,
        address,
        phone,
        matriculeFiscale,
        ownerId: currentUser.uid,
        imageUrl,
        locals,
        createdAt: new Date(),
      });

      setMessage("Restaurant added successfully!");
      setCurrentStep(3);
      console.log("Restaurant ID:", docRef.id);
      navigate("/Home"); 
    } catch (error) {
      setMessage(`Error: ${error.message}`);
    }
  };

  // Step-wise form UI
  const renderStepContent = () => {
    switch (currentStep) {
      case 1:
        return (
          <>
            <TextField
              label="Restaurant Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
              fullWidth
              sx={{ mb: 2 }}
            />
            <TextField
              label="Address"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              required
              fullWidth
              sx={{ mb: 2 }}
            />
            <TextField
              label="Phone"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              required
              fullWidth
              sx={{ mb: 2 }}
            />
            <TextField
              label="Matricule Fiscale"
              value={matriculeFiscale}
              onChange={(e) => setMatriculeFiscale(e.target.value)}
              required
              fullWidth
              sx={{ mb: 2 }}
            />
            <Button variant="contained" color="primary" onClick={() => setCurrentStep(2)} sx={{ mt: 2 }}>
              Next
            </Button>
          </>
        );
      case 2:
        return (
          <>
            <Typography variant="h5" sx={{ mb: 2 }}>Upload Restaurant Picture and Add Local</Typography>
            <input type="file" accept="image/*" onChange={handleFileChange} />
            {RestoPicPreview && (
              <Box mt={2}>
                <img src={RestoPicPreview} alt="Preview" width="100%" style={{ borderRadius: 8 }} />
              </Box>
            )}
            <TextField
              label="Enter Local Address"
              value={localInput}
              onChange={(e) => setLocalInput(e.target.value)}
              fullWidth
              sx={{ mt: 2, mb: 2 }}
            />
            <Button variant="outlined" onClick={handleAddLocal} sx={{ mb: 2 }}>
              Add Local
            </Button>
            <List>
              {locals.map((local, index) => (
                <ListItem key={index}>
                  <ListItemText primary={local} />
                </ListItem>
              ))}
            </List>
            <Button variant="contained" color="primary" onClick={handleAddRestaurant} sx={{ mt: 2 }}>
              Add Restaurant
            </Button>
          </>
        );
      case 3:
        return (
          <Box sx={{ textAlign: "center" }}>
            <Typography variant="h4" color="success.main" gutterBottom>
              Restaurant Added Successfully!
            </Typography>
          </Box>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <Button variant="contained" color="primary" onClick={handleOpen}>
        Add Restaurant
      </Button>
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle>Add a Restaurant</DialogTitle>
        <DialogContent dividers>{renderStepContent()}</DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">Cancel</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddRestaurantFormPopup;
