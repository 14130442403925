import React, { useState, useEffect, useMemo } from "react";
import RapportX from "../../Services/RapportX";
import {
  IconButton,
  Tooltip,
  CircularProgress,
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Box,
  useMediaQuery,
  TablePagination
} from "@mui/material";
import {
  CheckCircle,
  Error,
  
  ArrowUpward,
  ArrowDownward,
  Print,
  ChevronLeft,
  ChevronRight
} from "@mui/icons-material";

const ExcelTable = ({ data }) => {
  const [rapportXValues, setRapportXValues] = useState({});
  const [totalRapportX, setTotalRapportX] = useState(0);
  const [savingStatus, setSavingStatus] = useState({});
  const [currentPage, setCurrentPage] = useState(0);
  const restaurantId = localStorage.getItem("restaurantId");
  const isMobile = useMediaQuery('(max-width:600px)');

  // Group data by month
  const { monthsData, initialPage } = useMemo(() => {
    if (!data) return { monthsData: [], initialPage: 0 };

    const groupedByMonth = data.reduce((acc, row) => {
      const date = new Date(row.date);
      const monthKey = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}`;
      
      if (!acc[monthKey]) {
        acc[monthKey] = {
          monthKey,
          dates: {},
          encaissement: {},
          decaissement: {},
          totals: { encaissement: 0, decaissement: 0, caisse: 0 }
        };
      }

      const category = row.category === 'ENCAISSEMENT' ? 'encaissement' : 'decaissement';
      const type = row.type;
      
      // Daily values
      if (!acc[monthKey].dates[row.date]) {
        acc[monthKey].dates[row.date] = {
          date: row.date,
          encaissement: {},
          decaissement: {},
          totalCaisse: 0
        };
      }
      
      acc[monthKey].dates[row.date][category][type] = 
        (acc[monthKey].dates[row.date][category][type] || 0) + row.value;
      acc[monthKey].dates[row.date].totalCaisse += row.value;

      // Monthly totals
      acc[monthKey].totals[category] += row.value;
      acc[monthKey].totals.caisse += row.value;

      return acc;
    }, {});

    const monthsData = Object.values(groupedByMonth)
      .sort((a, b) => new Date(b.monthKey) - new Date(a.monthKey))
      .map(month => ({
        ...month,
        dates: Object.values(month.dates).sort((a, b) => new Date(b.date) - new Date(a.date))
      }));

    // Find current month index
    const currentDate = new Date();
    const currentMonthKey = `${currentDate.getFullYear()}-${String(currentDate.getMonth() + 1).padStart(2, '0')}`;
    const initialPage = monthsData.findIndex(m => m.monthKey === currentMonthKey);

    return { monthsData, initialPage: initialPage !== -1 ? initialPage : 0 };
  }, [data]);

  useEffect(() => {
    setCurrentPage(initialPage);
  }, [initialPage]);

 useEffect(() => {
    const fetchRapportX = async () => {
      try {
        const allRapportX = await RapportX.getAllRapportX(restaurantId);
        const now = new Date();
        const currentMonth = now.getMonth();
        const currentYear = now.getFullYear();
  
        // Filter items to only include those with dates in the current month and year
        const filteredRapportX = allRapportX.filter(item => {
          const itemDate = new Date(item.date);
          return (
            itemDate.getMonth() === currentMonth &&
            itemDate.getFullYear() === currentYear
          );
        });
  
        // Map the filtered items to an object with the date as key and the value as value
        const mappedValues = filteredRapportX.reduce((acc, item) => {
          acc[item.date] = item.value;
          return acc;
        }, {});
  
        setRapportXValues(mappedValues);
      } catch (error) {
        console.error("Error fetching RapportX:", error.message);
      }
    };
  
    fetchRapportX();
  }, [restaurantId]);
  
  // Fetch total RapportX for the current month
  useEffect(() => {
    const fetchTotalRapportX = async () => {
      try {
        const allRapportX = await RapportX.getAllRapportX(restaurantId);
        const now = new Date();
        const currentMonth = now.getMonth();
        const currentYear = now.getFullYear();
  
        // Filter items for the current month/year
        const filteredRapportX = allRapportX.filter(item => {
          const itemDate = new Date(item.date);
          return (
            itemDate.getMonth() === currentMonth &&
            itemDate.getFullYear() === currentYear
          );
        });
  
        // Sum up the values (ensuring proper conversion to number)
        const total = filteredRapportX.reduce((sum, item) => {
          return sum + Number(item.value);
        }, 0);
  
        setTotalRapportX(total);
      } catch (error) {
        console.error("Error fetching total RapportX:", error.message);
      }
    };
  
    if (restaurantId) {
      fetchTotalRapportX();
    }
  }, [restaurantId]);
  
  const handleInputChange = async (date, value) => {
    setRapportXValues((prev) => ({ ...prev, [date]: value }));
    setSavingStatus((prev) => ({ ...prev, [date]: 'saving' }));

    try {
      await RapportX.saveRapportX(restaurantId, date, Number(value));
      setSavingStatus((prev) => ({ ...prev, [date]: 'saved' }));
    } catch (error) {
      console.error("Error saving RapportX:", error.message);
      setSavingStatus((prev) => ({ ...prev, [date]: 'error' }));
    }
  };

  const formatNumber = (value) => 
    new Intl.NumberFormat('en-US', { minimumFractionDigits: 2 }).format(value || 0);

  const getEcartColor = (value) => value >= 0 ? '#2e7d32' : '#d32f2f';
  const getEcartIcon = (value) => value >= 0 ? <ArrowUpward fontSize="small" /> : <ArrowDownward fontSize="small" />;

  const handlePreviousPage = () => setCurrentPage(prev => Math.max(prev - 1, 0));
  const handleNextPage = () => setCurrentPage(prev => Math.min(prev + 1, monthsData.length - 1));

  const formatMonth = (monthKey) => {
    const [year, month] = monthKey.split('-');
    return new Date(year, month - 1).toLocaleDateString('en-US', { month: 'long', year: 'numeric' });
  };

  if (!monthsData.length) {
    return (
      <Paper elevation={3} sx={{ p: 2, textAlign: 'center' }}>
        <Typography variant="body1">No financial data available</Typography>
      </Paper>
    );
  }

  const currentMonth = monthsData[currentPage];
  const categories = {
    encaissement: [...new Set(data.filter(r => r.category === 'ENCAISSEMENT').map(r => r.type))],
    decaissement: [...new Set(data.filter(r => r.category === 'DECAISSEMENT').map(r => r.type))]
  };

  return (
    <Paper elevation={3} sx={{ p: 1, overflowX: 'auto' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1, p: 1 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <IconButton onClick={handlePreviousPage} disabled={currentPage === 0}>
            <ChevronLeft />
          </IconButton>
          <Typography variant="h6" component="div">
            {formatMonth(currentMonth.monthKey)}
          </Typography>
          <IconButton onClick={handleNextPage} disabled={currentPage === monthsData.length - 1}>
            <ChevronRight />
          </IconButton>
        </Box>
        <Box>
          <IconButton onClick={() => window.print()} size="small">
            <Print fontSize="small" />
          </IconButton>
        </Box>
      </Box>

      <TableContainer>
        <Table size="small" sx={{ minWidth: 600 }}>
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: 'bold', width: '100px' }}>Date</TableCell>
              {categories.encaissement.map(type => (
                <TableCell key={`enc-${type}`} sx={{ fontWeight: 'bold', bgcolor: '#e8f5e9', width: '120px', p: 1 }}>
                  <Typography variant="caption">{type}</Typography>
                </TableCell>
              ))}
              {categories.decaissement.map(type => (
                <TableCell key={`dec-${type}`} sx={{ fontWeight: 'bold', bgcolor: '#ffebee', width: '120px', p: 1 }}>
                  <Typography variant="caption">{type}</Typography>
                </TableCell>
              ))}
              <TableCell sx={{ fontWeight: 'bold', bgcolor: '#e3f2fd', width: '100px' }}>Daily Total</TableCell>
              <TableCell sx={{ fontWeight: 'bold', bgcolor: '#e3f2fd', width: '140px' }}>Rapport X</TableCell>
              <TableCell sx={{ fontWeight: 'bold', bgcolor: '#e3f2fd', width: '100px' }}>Écart</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {currentMonth.dates.map((dateRow) => {
              const rapportX = rapportXValues[dateRow.date] || 0;
              const ecart = dateRow.totalCaisse - rapportX;

              return (
                <TableRow key={dateRow.date} hover>
                  <TableCell sx={{ p: 1 }}>
                    <Typography variant="caption">{dateRow.date}</Typography>
                  </TableCell>
                  
                  {categories.encaissement.map(type => (
                    <TableCell key={`${dateRow.date}-enc-${type}`} sx={{ bgcolor: '#f1f8e9', p: 1 }}>
                      <Typography variant="caption">
                        {formatNumber(dateRow.encaissement[type] || 0)}
                      </Typography>
                    </TableCell>
                  ))}
                  
                  {categories.decaissement.map(type => (
                    <TableCell key={`${dateRow.date}-dec-${type}`} sx={{ bgcolor: '#ffebee', p: 1 }}>
                      <Typography variant="caption">
                        {formatNumber(dateRow.decaissement[type] || 0)}
                      </Typography>
                    </TableCell>
                  ))}
                  
                  <TableCell sx={{ bgcolor: '#e1f5fe', p: 1 }}>
                    <Typography variant="caption" fontWeight="medium">
                      {formatNumber(dateRow.totalCaisse)}
                    </Typography>
                  </TableCell>
                  
                  <TableCell sx={{ bgcolor: '#e1f5fe', p: 1 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                      <TextField
                        size="small"
                        variant="outlined"
                        value={rapportX.toLocaleString()}
                        onChange={(e) => {
                          const value = e.target.value.replace(/,/g, '');
                          if (/^\d*\.?\d*$/.test(value)) handleInputChange(dateRow.date, value);
                        }}
                        onBlur={(e) => handleInputChange(dateRow.date, Number(e.target.value.replace(/,/g, '')))}
                        sx={{ width: '80px' }}
                        inputProps={{ style: { fontSize: '0.75rem' } }}
                      />
                      {savingStatus[dateRow.date] === 'saving' && <CircularProgress size={16} />}
                      {savingStatus[dateRow.date] === 'saved' && <CheckCircle color="success" fontSize="small" />}
                      {savingStatus[dateRow.date] === 'error' && <Error color="error" fontSize="small" />}
                    </Box>
                  </TableCell>
                  
                  <TableCell sx={{ color: getEcartColor(ecart), p: 1, fontWeight: 'medium' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                      {getEcartIcon(ecart)}
                      <Typography variant="caption">
                        {formatNumber(Math.abs(ecart))}
                      </Typography>
                      {Math.abs(ecart) > 100 && (
                        <Tooltip title="Significant discrepancy">
                          <Error color="error" fontSize="small" />
                        </Tooltip>
                      )}
                    </Box>
                  </TableCell>
                </TableRow>
              );
            })}

            {/* Monthly Summary Row */}
            <TableRow sx={{ bgcolor: '#e8eaf6' }}>
              <TableCell colSpan={categories.encaissement.length + categories.decaissement.length + 2} align="right">
                <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
                  Monthly Total: {formatNumber(currentMonth.totals.caisse)}
                </Typography>
              </TableCell>
              <TableCell colSpan={2} align="right">
                <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
                  Monthly Écart: {formatNumber(currentMonth.totals.caisse - (totalRapportX) , 0)}
                </Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        component="div"
        count={monthsData.length}
        page={currentPage}
        onPageChange={(e, newPage) => setCurrentPage(newPage)}
        rowsPerPage={1}
        rowsPerPageOptions={[]}
        sx={{ borderTop: '1px solid rgba(224, 224, 224, 1)' }}
      />
    </Paper>
  );
};

export default ExcelTable;