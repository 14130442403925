import { 
  getFirestore, doc, setDoc, getDoc, updateDoc, deleteDoc, 
  collection, getDocs, query, where 
} from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
const db = getFirestore();
const storage = getStorage();

// ==================== Product Class ====================
class Product {
  constructor(name, soldPrice, restaurantId, image, ingredients) {
    this.name = name;
    this.soldPrice = soldPrice;
    this.restaurantId = restaurantId;
    this.image = image;
    this.ingredients = ingredients; // [{ stockName: "Tomato", quantity: 0.2 }]
    this.costPrice = 0;
  }

  async calculateAndUpdateCost() {
    try {
      let totalCost = 0;
      for (const item of this.ingredients) {
        const stockQuery = query(collection(db, "stocks"), where("productName", "==", item.stockName));
        const stockSnap = await getDocs(stockQuery);
        if (!stockSnap.empty) {
          const stockData = stockSnap.docs[0].data();
          totalCost += item.quantity * stockData.pricePerUnit;
        }
      }
      this.costPrice = totalCost;
      console.log(`Updated cost price for ${this.name}: ${this.costPrice}`);
      return totalCost;
    } catch (error) {
      console.error("Error calculating product cost:", error.message);
      throw error;
    }
  }

  async addProduct() {
    try {
      this.costPrice = await this.calculateAndUpdateCost();
      const productRef = doc(collection(db, "products"));
      await setDoc(productRef, {
        name: this.name,
        soldPrice: this.soldPrice,
        restaurantId: this.restaurantId,
        image: this.image,
        ingredients: this.ingredients,
        costPrice: this.costPrice
      });
      console.log("Product added successfully!");
    } catch (error) {
      console.error("Error adding product:", error.message);
      throw error;
    }
  }

  async updateProduct(productId, updatedData) {
    try {
      if (updatedData.ingredients) {
        this.ingredients = updatedData.ingredients;
        updatedData.costPrice = await this.calculateAndUpdateCost();
      }
      const productRef = doc(db, "products", productId);
      await updateDoc(productRef, updatedData);
      console.log("Product updated successfully!");
    } catch (error) {
      console.error("Error updating product:", error.message);
      throw error;
    }
  }

  async deleteProduct(productId) {
    try {
      const productRef = doc(db, "products", productId);
      await deleteDoc(productRef);
      console.log("Product deleted successfully!");
    } catch (error) {
      console.error("Error deleting product:", error.message);
      throw error;
    }
  }

  static async getAllProducts() {
    try {
      const productsRef = collection(db, "products");
      const productDocs = await getDocs(productsRef);
      return productDocs.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    } catch (error) {
      console.error("Error getting products:", error.message);
      throw error;
    }
  }
}

export default Product;
