import { getFirestore, doc, setDoc, getDoc, deleteDoc, collection, getDocs } from "firebase/firestore";

const db = getFirestore();

class RapportX {
  constructor(restaurantId, date, value) {
    this.restaurantId = restaurantId;
    this.date = date;
    this.value = value;
  }

  // Save or update RapportX
  static async saveRapportX(restaurantId, date, value) {
    try {
      const rapportXRef = doc(db, "rapportX", `${restaurantId}_${date}`); // Unique ID
      await setDoc(rapportXRef, { restaurantId, date, value }, { merge: true });
      console.log("RapportX saved successfully!");
    } catch (error) {
      console.error("Error saving RapportX:", error.message);
      throw new Error(error.message);
    }
  }

  // Get all RapportX by restaurantId
  static async getAllRapportX(restaurantId) {
    try {
      const rapportXSnapshot = await getDocs(collection(db, "rapportX"));
      const rapportXList = [];

      rapportXSnapshot.forEach((doc) => {
        const data = doc.data();
        if (data.restaurantId === restaurantId) {
          rapportXList.push(data);
        }
      });

      return rapportXList;
    } catch (error) {
      console.error("Error retrieving RapportX data:", error.message);
      throw new Error(error.message);
    }
  }

  // Get total RapportX per restaurant from each first of the month (01/MM/YYYY)
  static async getTotalRapportXPerMonth(restaurantId) {
    try {
      const rapportXSnapshot = await getDocs(collection(db, "rapportX"));
      let totalSum = 0;

      const currentDate = new Date();
      const currentMonth = String(currentDate.getMonth() + 1).padStart(2, "0"); // Ensure 2-digit month
      const currentYear = currentDate.getFullYear().toString();

      rapportXSnapshot.forEach((doc) => {
        const data = doc.data();
        if (data.restaurantId === restaurantId) {
          const [day, month, year] = data.date.split("/");
          if (month === currentMonth && year === currentYear) {
            totalSum += data.value;
          }
        }
      });

      return totalSum;
    } catch (error) {
      console.error("Error calculating total RapportX:", error.message);
      throw new Error(error.message);
    }
  }

  // Delete RapportX by restaurantId and date
  static async deleteRapportX(restaurantId, date) {
    try {
      const rapportXRef = doc(db, "rapportX", `${restaurantId}_${date}`);
      await deleteDoc(rapportXRef);
      console.log("RapportX deleted successfully!");
    } catch (error) {
      console.error("Error deleting RapportX:", error.message);
      throw new Error(error.message);
    }
  }
}

export default RapportX;
