import { 
  getFirestore, doc, setDoc, getDoc, updateDoc, deleteDoc, 
  collection, getDocs, query, where, increment 
} from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import Product from "./FoodCost";
const db = getFirestore();
const storage = getStorage();

// ==================== Stock Class ====================
class Stock {
  constructor(productName, pricePerUnit, date, quantity, restaurantId) {
    this.productName = productName;
    this.pricePerUnit = pricePerUnit;
    this.date = date;
    this.quantity = quantity;
    this.restaurantId = restaurantId;
  }

  async addStock() {
    try {
      const stocksRef = collection(db, "stocks");
      const q = query(stocksRef, where("productName", "==", this.productName), where("restaurantId", "==", this.restaurantId));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        // Update existing stock
        const existingDoc = querySnapshot.docs[0];
        await updateDoc(existingDoc.ref, {
          quantity: increment(this.quantity),
          pricePerUnit: this.pricePerUnit,
          date: this.date
        });
        console.log('Stock updated successfully!');
      } else {
        // Create new stock
        const newStockRef = doc(stocksRef);
        await setDoc(newStockRef, {
          productName: this.productName,
          pricePerUnit: this.pricePerUnit,
          date: this.date,
          quantity: this.quantity,
          restaurantId: this.restaurantId
        });
        console.log('Stock added successfully!');
      }
    } catch (error) {
      console.error('Error adding stock:', error.message);
      throw error;
    }
  }

  async updateStock(stockId, updatedData) {
    try {
      const stockRef = doc(db, "stocks", stockId);
      await setDoc(stockRef, updatedData, { merge: true });

      // Update related products
      const stockSnap = await getDoc(stockRef);
      const stockName = stockSnap.data().productName;
      const restaurantId = stockSnap.data().restaurantId;

      const productsRef = collection(db, "products");
      const q = query(productsRef, where("restaurantId", "==", restaurantId));
      const productsSnap = await getDocs(q);
      
      const updatePromises = [];
      productsSnap.forEach(doc => {
        if (doc.data().ingredients.some(i => i.stockName === stockName)) {
          const product = new Product(
            doc.data().name,
            doc.data().soldPrice,
            doc.data().restaurantId,
            doc.data().image,
            doc.data().ingredients
          );
          product.id = doc.id;
          updatePromises.push(product.calculateAndUpdateCost());
        }
      });

      await Promise.all(updatePromises);
      console.log('Stock and related products updated!');
    } catch (error) {
      console.error('Error updating stock:', error.message);
      throw error;
    }
  }

  async deleteStock(stockId) {
    try {
      const stockRef = doc(db, "stocks", stockId);
      await deleteDoc(stockRef);
      console.log('Stock deleted successfully!');
    } catch (error) {
      console.error('Error deleting stock:', error.message);
      throw error;
    }
  }

  static async getAllStocks(restaurantId) {
    try {
      const stocksRef = collection(db, "stocks");
      const q = query(stocksRef, where("restaurantId", "==", restaurantId));
      const stockDocs = await getDocs(q);
      return stockDocs.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    } catch (error) {
      console.error('Error getting stocks:', error.message);
      throw error;
    }
  }
}

export default Stock;
