import React, { useState, useEffect } from "react";
import Decaissement from "../Services/Decaisement";
import ExcelTable4 from "../Components/TableExcel/TableExcel";
import {
  Card,
  CardContent,
  Typography,
  TextField,
  Button,
  Box,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Grid,
  useTheme,
  Tooltip,
  Avatar,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import FilterListIcon from "@mui/icons-material/FilterList";
import DateRangeIcon from "@mui/icons-material/DateRange";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import { lightRed, red } from "@mui/material/colors";

const DecaissementsPage = () => {
  const [decaissements, setDecaissements] = useState([]);
  const [newDecaissement, setNewDecaissement] = useState({
    value: "",
    type: "",
    date: new Date().toISOString().split("T")[0],
  });
  const theme = useTheme();
  const [editingDecaissement, setEditingDecaissement] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [openDialog, setOpenDialog] = useState(false);
  const [existingTypes, setExistingTypes] = useState([]);
  const [newType, setNewType] = useState("");
    const [searchType, setSearchType] = useState("");
    const restaurantId = localStorage.getItem("restaurantId");
    const [dateDebut, setDateDebut] = useState("");
    const [dateFin, setDateFin] = useState("");

  // Fetch data on component mount
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch decaissements by restaurant ID
        const fetchedDecaissements = await Decaissement.getDecaissementsByRestaurantId(restaurantId);
        setDecaissements(fetchedDecaissements);
  
        // Extract unique types
        const uniqueTypes = [...new Set(fetchedDecaissements.map(d => d.type))];
        setExistingTypes(uniqueTypes);
      } catch (error) {
        console.error("Error fetching data:", error.message);
      }
    };
    fetchData();
  }, [restaurantId]);
  
  const filteredDecaissements = decaissements.filter((decaissement) => {
    // Filter by type
    const typeFilter = searchType ? decaissement.type === searchType : true;
    
    // Filter by date range
    const dateDebutFilter = dateDebut ? new Date(decaissement.date) >= new Date(dateDebut) : true;
    const dateFinFilter = dateFin ? new Date(decaissement.date) <= new Date(dateFin) : true;
    
    return typeFilter && dateDebutFilter && dateFinFilter;
  });

  const handleAddDecaissement = async () => {
    if (!newDecaissement.value || !newDecaissement.type || !newDecaissement.date) {
      alert("Please fill in all fields");
      return;
    }

    try {
      const typeToUse = newDecaissement.type === "newType" ? newType : newDecaissement.type;
      const decaissement = new Decaissement(
        parseFloat(newDecaissement.value),
        typeToUse,
        newDecaissement.date,
        restaurantId
      );
      await decaissement.addDecaissement();
      setDecaissements([...decaissements, decaissement]);

      setNewDecaissement({
        value: "",
        type: "",
        date: new Date().toISOString().split("T")[0],
      });
      setNewType("");
      setOpenDialog(false);
    } catch (error) {
      console.error("Error adding decaissement:", error.message);
    }
  };

  const handleEditDecaissement = (decaissement) => {
    setEditingDecaissement(decaissement);
    setNewDecaissement({
      value: parseFloat(decaissement.value),
      type: decaissement.type,
      date: decaissement.date,
    });
    setOpenDialog(true);
  };

  const handleSaveEdit = async () => {
    if (!newDecaissement.value || !newDecaissement.type || !newDecaissement.date) {
      alert("Please fill in all fields");
      return;
    }

    try {
      const updates = {
        value: parseFloat(newDecaissement.value),
        type: newDecaissement.type,
        date: newDecaissement.date,
        restaurantId,
      };

      await Decaissement.updateDecaissement(editingDecaissement.id, updates);

      setDecaissements((prev) =>
        prev.map((dec) =>
          dec.id === editingDecaissement.id ? { ...dec, ...updates } : dec
        )
      );

      setEditingDecaissement(null);
      setNewDecaissement({
        value: "",
        type: "",
        date: new Date().toISOString().split("T")[0],
      });
      setOpenDialog(false);
    } catch (error) {
      console.error("Error saving edit:", error.message);
    }
  };

  const handleDeleteDecaissement = async (id) => {
    try {
      await Decaissement.deleteDecaissement(id);
      setDecaissements((prev) =>
        prev.filter((decaissement) => decaissement.id !== id)
      );
    } catch (error) {
      console.error("Error deleting decaissement:", error.message);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Box sx={{ p: 3, backgroundColor: theme.palette.background.default }}>
      {/* Header Section */}
      <Card sx={{ 
        mb: 4, 
        background: 'linear-gradient(45deg, #ff5252 30%, #d32f2f 90%)',
        boxShadow: 3,
        borderRadius: 4
      }}>
        <CardContent>
          <Box sx={{ 
            display: 'flex', 
            alignItems: 'center', 
            gap: 3,
            color: 'common.white'
          }}>
            <Avatar sx={{ 
              bgcolor: 'common.white', 
              width: 56, 
              height: 56 
            }}>
              <AttachMoneyIcon sx={{ color: 'error.main', fontSize: 32 }} />
            </Avatar>
            <Box>
              <Typography variant="h4" sx={{ fontWeight: 700 }}>
                Decaissement Management
              </Typography>
              <Typography variant="body1">
                Track and manage all financial expenditures
              </Typography>
            </Box>
          </Box>
        </CardContent>
      </Card>

      {/* Filters Section */}
      <Card sx={{ mb: 4, borderRadius: 3, boxShadow: 2 }}>
        <CardContent>
          <Grid container spacing={3} sx={{ alignItems: 'center' }}>
            <Grid item xs={12} md={3}>
              <FormControl fullWidth variant="filled">
                <InputLabel>Filter by Type</InputLabel>
                <Select
                  value={searchType}
                  onChange={(e) => setSearchType(e.target.value)}
                  startAdornment={<FilterListIcon sx={{ color: 'action.active', mr: 1 }} />}
                >
                  <MenuItem value="">All Types</MenuItem>
                  {existingTypes.map((type) => (
                    <MenuItem key={type} value={type}>{type}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={3}>
              <TextField
                fullWidth
                variant="filled"
                label="Start Date"
                type="date"
                value={dateDebut}
                onChange={(e) => setDateDebut(e.target.value)}
                InputProps={{
                  startAdornment: <DateRangeIcon sx={{ color: 'action.active', mr: 1 }} />,
                }}
              />
            </Grid>

            <Grid item xs={12} md={3}>
              <TextField
                fullWidth
                variant="filled"
                label="End Date"
                type="date"
                value={dateFin}
                onChange={(e) => setDateFin(e.target.value)}
                InputProps={{
                  startAdornment: <DateRangeIcon sx={{ color: 'action.active', mr: 1 }} />,
                }}
              />
            </Grid>

            <Grid item xs={12} md={3}>
              <Button
                fullWidth
                variant="contained"
                startIcon={<AddCircleIcon />}
                onClick={() => setOpenDialog(true)}
                sx={{
                  height: 56,
                  borderRadius: 2,
                  background: 'linear-gradient(45deg, #ff5252 30%, #d32f2f 90%)',
                  '&:hover': {
                    transform: 'translateY(-2px)',
                    boxShadow: 3
                  }
                }}
              >
                New Decaissement
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      {/* Data Section */}
      <Card sx={{ mb: 4, borderRadius: 3, boxShadow: 2 }}>
        <CardContent>
          <ExcelTable4 data={filteredDecaissements} title="DECAISSEMENT" />
        </CardContent>
      </Card>

      {/* Transactions Table */}
      <Card sx={{ borderRadius: 3, boxShadow: 2 }}>
        <TableContainer>
          <Table sx={{ minWidth: 800 }}>
            <TableHead sx={{ bgcolor: 'background.paper' }}>
              <TableRow>
                {['Date', 'Amount', 'Type', 'Actions'].map((header) => (
                  <TableCell key={header} sx={{ 
                    fontWeight: 600, 
                    fontSize: '1rem',
                    py: 2,
                    borderBottom: `2px solid ${theme.palette.divider}`
                  }}>
                    {header}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            
            <TableBody>
              {filteredDecaissements
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((decaissement) => (
                  <TableRow 
                    key={decaissement.id} 
                    hover
                    sx={{ '&:nth-of-type(odd)': { bgcolor: 'action.hover' } }}
                  >
                    <TableCell sx={{ fontWeight: 500 }}>
                      {new Date(decaissement.date).toLocaleDateString()}
                    </TableCell>
                    <TableCell sx={{ color: 'error.main', fontWeight: 600 }}>
                      {Number(decaissement.value).toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'TND'
                      })}
                    </TableCell>
                    <TableCell>
                      <Box
                        component="span"
                        sx={{
                          px: 1.5,
                          py: 0.5,
                          borderRadius: 1,
                          bgcolor: 'error.light',
                          color: 'error.contrastText'
                        }}
                      >
                        {decaissement.type}
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Tooltip title="Edit">
                        <IconButton 
                          color="info"
                          onClick={() => handleEditDecaissement(decaissement)}
                        >
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Delete">
                        <IconButton 
                          color="error"
                          onClick={() => handleDeleteDecaissement(decaissement.id)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filteredDecaissements.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{ borderTop: `1px solid ${theme.palette.divider}` }}
        />
      </Card>

      {/* Add/Edit Dialog */}
      <Dialog 
        open={openDialog} 
        onClose={() => setOpenDialog(false)}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle sx={{ 
          bgcolor: 'background.paper', 
          borderBottom: `1px solid ${theme.palette.divider}`,
          py: 2
        }}>
          <Typography variant="h6" sx={{ fontWeight: 600 }}>
            {editingDecaissement ? 'Edit Transaction' : 'New Transaction'}
          </Typography>
        </DialogTitle>
        
        <DialogContent sx={{ py: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="filled"
                label="Amount (TND)"
                type="number"
                value={newDecaissement.value}
                onChange={(e) => setNewDecaissement({ 
                  ...newDecaissement, 
                  value: e.target.value 
                })}
                InputProps={{
                  startAdornment: <Typography sx={{ mr: 1 }}>TND</Typography>,
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth variant="filled">
                <InputLabel>Transaction Type</InputLabel>
                <Select
                  value={newDecaissement.type}
                  onChange={(e) => setNewDecaissement({
                    ...newDecaissement,
                    type: e.target.value
                  })}
                >
                  <MenuItem value="">Select Type</MenuItem>
                  {existingTypes.map((type) => (
                    <MenuItem key={type} value={type}>{type}</MenuItem>
                  ))}
                  <MenuItem value="newType">Custom Type</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            {newDecaissement.type === "newType" && (
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  variant="filled"
                  label="Custom Type Name"
                  value={newType}
                  onChange={(e) => setNewType(e.target.value)}
                />
              </Grid>
            )}

            <Grid item xs={12}>
              <TextField
                fullWidth
                variant="filled"
                label="Transaction Date"
                type="date"
                value={newDecaissement.date}
                onChange={(e) => setNewDecaissement({ 
                  ...newDecaissement, 
                  date: e.target.value 
                })}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions sx={{ px: 3, py: 2 }}>
          <Button 
            onClick={() => setOpenDialog(false)}
            color="inherit"
            sx={{ mr: 2 }}
          >
            Cancel
          </Button>
          <Button 
            variant="contained"
            onClick={editingDecaissement ? handleSaveEdit : handleAddDecaissement}
            sx={{ 
              px: 4,
              background: 'linear-gradient(45deg, #ff5252 30%, #d32f2f 90%)'
            }}
          >
            {editingDecaissement ? 'Save Changes' : 'Create'}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default DecaissementsPage;
