import React, { useState, useEffect } from "react";
import { 
  Container,
  Typography,
  TextField,
  Button,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Paper,
  Grid,
  Divider,
  Box,
  Chip,
  Avatar,
  Card,
  CardContent,
  CardActions,
} from "@mui/material";
import { 
  AddCircle, 
  Delete, 
  Edit, 
  RemoveCircle,
  Save, 
  Cancel,
  LocalDining,
  Kitchen,
  AttachMoney,
  Scale
} from "@mui/icons-material";
import Product from "../Services/FoodCost";
import Stock from "../Services/Stock";
import ExcelTableFoodCost from "../Components/TableExcel/ExcelFoodCost";
const FoodCostPage = () => {
  const [products, setProducts] = useState([]);
  const [stocks, setStocks] = useState([]);
  const [form, setForm] = useState({ name: "", soldPrice: "", ingredients: [] });
  const [editingProduct, setEditingProduct] = useState(null);
 // New states for daily servings

 const restaurantId = localStorage.getItem("restaurantId");
 const [dailyServings, setDailyServings] = useState([]);
 const [selectedProducts, setSelectedProducts] = useState([]);
 const [currentServing, setCurrentServing] = useState({ 
   id: null, 
   items: [], 
   total: 0 
 });

  useEffect(() => {
    fetchProducts();
    fetchStocks();
  }, []);

  const fetchProducts = async () => {
    const data = await Product.getAllProducts();
    setProducts(data);
  };

  const fetchStocks = async () => {
    const data = await Stock.getAllStocks();
    setStocks(data);
  };

  const handleInputChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleIngredientChange = (index, field, value) => {
    const newIngredients = [...form.ingredients];
    newIngredients[index][field] = value;
    setForm({ ...form, ingredients: newIngredients });
  };

  const addIngredient = () => {
    setForm({ ...form, ingredients: [...form.ingredients, { stockName: "", quantity: 0 }] });
  };

  const removeIngredient = (index) => {
    const newIngredients = form.ingredients.filter((_, i) => i !== index);
    setForm({ ...form, ingredients: newIngredients });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const product = new Product(form.name, parseFloat(form.soldPrice), restaurantId, "", form.ingredients);
    if (editingProduct) {
      await product.updateProduct(editingProduct, form);
    } else {
      await product.addProduct();
    }
    fetchProducts();
    setForm({ name: "", soldPrice: "", ingredients: [] });
    setEditingProduct(null);
  };

  const handleEdit = (product) => {
    setEditingProduct(product.id);
    setForm(product);
  };

  const handleDelete = async (id) => {
    await new Product().deleteProduct(id);
    fetchProducts();
  };
  const addDailyServing = async () => {
    const newServing = {
      ...currentServing,
      id: currentServing.id || Date.now(),
      date: new Date().toISOString()
    };
    
    if (currentServing.id) {
      setDailyServings(dailyServings.map(s => s.id === currentServing.id ? newServing : s));
    } else {
      setDailyServings([...dailyServings, newServing]);
    }
    resetServingForm();
  };

  const editDailyServing = (serving) => {
    setCurrentServing(serving);
    setSelectedProducts(serving.items);
  };

  const deleteDailyServing = (id) => {
    setDailyServings(dailyServings.filter(s => s.id !== id));
  };

  const resetServingForm = () => {
    setCurrentServing({ id: null, items: [], total: 0 });
    setSelectedProducts([]);
  };

  // Product Selection and Quantity Handling
  const handleProductSelect = (product) => {
    if (!selectedProducts.some(p => p.id === product.id)) {
      setSelectedProducts([...selectedProducts, { ...product, quantity: 1 }]);
    }
  };

  const updateQuantity = (id, quantity) => {
    const updated = selectedProducts.map(p => 
      p.id === id ? { ...p, quantity: Math.max(1, quantity) } : p
    );
    setSelectedProducts(updated);
    
    // Update total
    const total = updated.reduce((sum, item) => 
      sum + (item.costPrice * item.quantity), 0);
    setCurrentServing(prev => ({ ...prev, items: updated, total }));
  };

  const removeProduct = (id) => {
    const filtered = selectedProducts.filter(p => p.id !== id);
    setSelectedProducts(filtered);
    setCurrentServing(prev => ({
      ...prev,
      items: filtered,
      total: filtered.reduce((sum, item) => sum + (item.costPrice * item.quantity), 0)
    }));
  };
  return (
    <Container maxWidth="lg" sx={{ my: 4 }}>
    
      
      <Paper elevation={3} sx={{ 
        p: 4, 
        borderRadius: 4,
        background: 'linear-gradient(145deg, #f5f5f5 0%, #ffffff 100%)',
      }}>
        {/* Header Section */}
        <Box sx={{ 
          display: 'flex', 
          alignItems: 'center', 
          mb: 4,
          gap: 2,
          padding: 2,
          borderRadius: 2,
          bgcolor: 'primary.main',
          color: 'white',
        }}>
          <LocalDining sx={{ fontSize: 40 }} />
          <Typography variant="h3" component="h1" sx={{ fontWeight: 700 }}>
            Food Cost Management
          </Typography>
        </Box>
        <ExcelTableFoodCost data={products} />
        {/* Daily Servings Section */}
        <Card sx={{ mb: 4, boxShadow: 3 }}>
          <CardContent>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2, gap: 1 }}>
              <Kitchen color="primary" sx={{ fontSize: 32 }} />
              <Typography variant="h5" component="h2">
                Daily Servings Management
              </Typography>
              <Chip 
                label={`Current Total: ${currentServing.total.toFixed(2)} DT`}
                color="primary"
                variant="outlined"
                sx={{ ml: 2, fontSize: '1rem', fontWeight: 600 }}
              />
            </Box>

            <Grid container spacing={2} sx={{ mb: 2 }}>
              <Grid item xs={12} md={8}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel>Select Menu Item</InputLabel>
                  <Select
                    value=""
                    onChange={(e) => handleProductSelect(JSON.parse(e.target.value))}
                    label="Select Menu Item"
                  >
                    {products.map(product => (
                      <MenuItem key={product.id} value={JSON.stringify(product)}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                          <Avatar sx={{ bgcolor: 'primary.main', width: 24, height: 24 }}>
                            <AttachMoney sx={{ fontSize: 16 }} />
                          </Avatar>
                          <span>{product.name}</span>
                          <Chip 
                            label={`${product.costPrice} DT`} 
                            size="small" 
                            color="secondary" 
                          />
                        </Box>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            {/* Selected Products Table */}
            {selectedProducts.length > 0 && (
              <Paper variant="outlined" sx={{ p: 2, mb: 2 }}>
                <Grid container spacing={1} sx={{ mb: 1, fontWeight: 600 }}>
                  <Grid item xs={5}>Product</Grid>
                  <Grid item xs={4}>Quantity</Grid>
                  <Grid item xs={3}>Actions</Grid>
                </Grid>
                {selectedProducts.map(product => (
                  <Grid container key={product.id} spacing={1} sx={{ alignItems: 'center', py: 1 }}>
                    <Grid item xs={5}>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <Scale fontSize="small" color="action" />
                        <Typography variant="body1">{product.name}</Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        fullWidth
                        type="number"
                        variant="outlined"
                        size="small"
                        value={product.quantity}
                        onChange={(e) => updateQuantity(product.id, parseInt(e.target.value))}
                        inputProps={{ min: 1, style: { textAlign: 'center' } }}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Button
                        variant="outlined"
                        color="error"
                        startIcon={<Delete />}
                        onClick={() => removeProduct(product.id)}
                        size="small"
                      >
                        Remove
                      </Button>
                    </Grid>
                  </Grid>
                ))}
              </Paper>
            )}

            {/* Action Buttons */}
            <Box sx={{ display: 'flex', gap: 2, mt: 3 }}>
              <Button
                variant="contained"
                color="primary"
                startIcon={<Save />}
                onClick={addDailyServing}
                disabled={selectedProducts.length === 0}
                sx={{ minWidth: 200 }}
              >
                {currentServing.id ? 'Update Serving' : 'Save Daily Plan'}
              </Button>
              {currentServing.id && (
                <Button
                  variant="text"
                  color="secondary"
                  startIcon={<Cancel />}
                  onClick={resetServingForm}
                >
                  Cancel Editing
                </Button>
              )}
            </Box>
          </CardContent>
        </Card>

        {/* Saved Servings List */}
        {dailyServings.length > 0 && (
          <Card sx={{ mb: 4, boxShadow: 3 }}>
            <CardContent>
              <Typography variant="h6" gutterBottom sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Kitchen color="action" /> Saved Servings
              </Typography>
              <Grid container spacing={2}>
                {dailyServings.map(serving => (
                  <Grid item xs={12} md={6} key={serving.id}>
                    <Paper elevation={1} sx={{ p: 2, position: 'relative' }}>
                      <Box sx={{ 
                        position: 'absolute', 
                        top: 8, 
                        right: 8, 
                        display: 'flex', 
                        gap: 1 
                      }}>
                        <IconButton 
                          size="small" 
                          onClick={() => editDailyServing(serving)}
                          color="primary"
                        >
                          <Edit fontSize="small" />
                        </IconButton>
                        <IconButton 
                          size="small" 
                          onClick={() => deleteDailyServing(serving.id)}
                          color="error"
                        >
                          <Delete fontSize="small" />
                        </IconButton>
                      </Box>
                      <Typography variant="subtitle2" color="textSecondary">
                        {new Date(serving.date).toLocaleDateString('en-US', {
                          weekday: 'long',
                          year: 'numeric',
                          month: 'long',
                          day: 'numeric'
                        })}
                      </Typography>
                      <Divider sx={{ my: 1 }} />
                      {serving.items.map(item => (
                        <Box key={item.id} sx={{ 
                          display: 'flex', 
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          py: 0.5
                        }}>
                          <Typography variant="body2">
                            {item.quantity}x {item.name}
                          </Typography>
                          <Typography variant="body2" color="textSecondary">
                            {(item.costPrice * item.quantity).toFixed(2)} DT
                          </Typography>
                        </Box>
                      ))}
                      <Divider sx={{ my: 1 }} />
                      <Box sx={{ 
                        display: 'flex', 
                        justifyContent: 'space-between',
                        alignItems: 'center'
                      }}>
                        <Typography variant="subtitle1">Total Cost:</Typography>
                        <Chip 
                          label={`${serving.total.toFixed(2)} DT`}
                          color="primary"
                          variant="outlined"
                          size="small"
                        />
                      </Box>
                    </Paper>
                  </Grid>
                ))}
              </Grid>
            </CardContent>
          </Card>
        )}

        {/* Product Form Section */}
        <Card sx={{ mb: 4, boxShadow: 3 }}>
          <CardContent>
            <Typography variant="h5" gutterBottom sx={{ 
              display: 'flex', 
              alignItems: 'center',
              gap: 1,
              mb: 3
            }}>
              <Kitchen color="primary" />
              {editingProduct ? 'Edit Menu Item' : 'Create New Menu Item'}
            </Typography>
            
            <form onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="Menu Item Name"
                    name="name"
                    value={form.name}
                    onChange={handleInputChange}
                    required
                    variant="outlined"
                    InputProps={{
                      startAdornment: (
                        <AttachMoney color="action" sx={{ mr: 1 }} />
                      )
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="Sale Price (DT)"
                    name="soldPrice"
                    type="number"
                    value={form.soldPrice}
                    onChange={handleInputChange}
                    required
                    variant="outlined"
                    InputProps={{
                      startAdornment: (
                        <AttachMoney color="action" sx={{ mr: 1 }} />
                      )
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Divider sx={{ my: 2 }} />
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
                    <Scale color="primary" />
                    <Typography variant="h6">Ingredients</Typography>
                  </Box>
                  
                  {form.ingredients.map((ingredient, index) => (
                    <Paper key={index} variant="outlined" sx={{ p: 1, mb: 1 }}>
                      <Grid container spacing={1} alignItems="center">
                        <Grid item xs={7} md={5}>
                          <FormControl fullWidth size="small">
                            <InputLabel>Ingredient</InputLabel>
                            <Select
                              value={ingredient.stockName}
                              onChange={(e) => handleIngredientChange(index, "stockName", e.target.value)}
                              label="Ingredient"
                            >
                              {stocks.map((stock) => (
                                <MenuItem key={stock.id} value={stock.productName}>
                                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                    <Kitchen sx={{ fontSize: 16 }} />
                                    {stock.productName}
                                  </Box>
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={3} md={2}>
                          <TextField
                            fullWidth
                            label="Qty"
                            type="number"
                            size="small"
                            value={ingredient.quantity}
                            onChange={(e) => handleIngredientChange(index, "quantity", parseFloat(e.target.value))}
                            required
                          />
                        </Grid>
                        <Grid item xs={2} md={1}>
                          <IconButton 
                            onClick={() => removeIngredient(index)} 
                            color="error"
                            size="small"
                          >
                            <Delete fontSize="small" />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Paper>
                  ))}

                  <Button
                    onClick={addIngredient}
                    startIcon={<AddCircle />}
                    variant="contained"
                    color="secondary"
                    size="small"
                    sx={{ mt: 1 }}
                  >
                    Add Ingredient
                  </Button>
                </Grid>

                <Grid item xs={12}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    size="large"
                    fullWidth
                    sx={{ height: 48 }}
                  >
                    {editingProduct ? "Update Menu Item" : "Create Menu Item"}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </CardContent>
        </Card>

        {/* Products List */}
        <Card sx={{ boxShadow: 3 }}>
          <CardContent>
            <Typography variant="h5" gutterBottom sx={{ 
              display: 'flex', 
              alignItems: 'center',
              gap: 1,
              mb: 2
            }}>
              <LocalDining color="primary" />
              Menu Items List
            </Typography>
            
            <Grid container spacing={2}>
              {products.map((product) => (
                <Grid item xs={12} md={6} key={product.id}>
                  <Paper variant="outlined" sx={{ p: 2 }}>
                    <Box sx={{ 
                      display: 'flex', 
                      justifyContent: 'space-between',
                      alignItems: 'center'
                    }}>
                      <Box>
                        <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                          {product.name}
                        </Typography>
                        <Box sx={{ display: 'flex', gap: 2, mt: 0.5 }}>
                          <Chip 
                            label={`Cost: ${product.costPrice} DT`}
                            size="small"
                            color="secondary"
                            variant="outlined"
                          />
                          <Chip 
                            label={`Sale: ${product.soldPrice} DT`}
                            size="small"
                            color="primary"
                            variant="outlined"
                          />
                        </Box>
                      </Box>
                      <Box>
                        <IconButton 
                          onClick={() => handleEdit(product)}
                          color="primary"
                          size="small"
                          sx={{ mr: 1 }}
                        >
                          <Edit fontSize="small" />
                        </IconButton>
                        <IconButton 
                          onClick={() => handleDelete(product.id)}
                          color="error"
                          size="small"
                        >
                          <Delete fontSize="small" />
                        </IconButton>
                      </Box>
                    </Box>
                  </Paper>
                </Grid>
              ))}
            </Grid>
          </CardContent>
        </Card>
      </Paper>
    </Container>
  );
};

export default FoodCostPage;