import React from 'react';
import { 
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
  styled
} from '@mui/material';
import { LocalDining, AttachMoney, Percent, Functions } from '@mui/icons-material';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: 600,
  '&:nth-of-type(1)': {
    width: '200px',
  },
}));

const HeaderCell = ({ children, icon: Icon }) => (
  <StyledTableCell align="center">
    <div style={{ display: 'flex', alignItems: 'center', gap: 8, justifyContent: 'center' }}>
      <Icon fontSize="small" />
      <span>{children}</span>
    </div>
  </StyledTableCell>
);

const ExcelTableFoodCost = ({ data }) => {
  const theme = useTheme();

  const updatedData = data.map((item) => {
    const ht = (item.costPrice * 3).toFixed(2);
    const ttc = (ht * 1.07).toFixed(2);
    const realHT = (item.soldPrice / 1.07).toFixed(2);
    const percentage = realHT && item.costPrice ? ((realHT / item.costPrice) * 100).toFixed(2) : 0;
    const coef = realHT ? ((item.costPrice / realHT) * 100).toFixed(2) : 0;

    return {
      ...item,
      ht,
      ttc,
      realHT,
      percentage,
      coef,
    };
  });

  return (
    <Paper elevation={3} sx={{ m: 3, overflowX: 'auto' }}>
      <TableContainer>
        <Table stickyHeader aria-label="food cost table">
          <TableHead>
            <TableRow sx={{ bgcolor: theme.palette.primary.light }}>
              <HeaderCell icon={LocalDining}>Nom De Produit</HeaderCell>
              <HeaderCell icon={AttachMoney}>FoodCost</HeaderCell>
              <HeaderCell icon={Functions}>P.H.T</HeaderCell>
              <HeaderCell icon={AttachMoney}>P.TTC</HeaderCell>
              <HeaderCell icon={AttachMoney}>P.Réel</HeaderCell>
              <HeaderCell icon={Functions}>P.R.H.T</HeaderCell>
              <HeaderCell icon={Percent}>Coef</HeaderCell>
              <HeaderCell icon={Percent}>%</HeaderCell>
            </TableRow>
          </TableHead>
          
          <TableBody>
            {updatedData.map((product) => (
              <TableRow 
                key={product.id}
                hover
                sx={{ '&:nth-of-type(even)': { bgcolor: theme.palette.action.hover } }}
              >
                <StyledTableCell>{product.name}</StyledTableCell>
                <StyledTableCell align="right">
                  {parseFloat(product.costPrice).toLocaleString('fr-FR', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  })}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {product.ht}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {product.ttc}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {parseFloat(product.soldPrice).toLocaleString('fr-FR', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  })}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {product.realHT}
                </StyledTableCell>
                <StyledTableCell align="right">
                  <span style={{ color: theme.palette.success.dark }}>
                    {product.coef}
                  </span>
                </StyledTableCell>
                <StyledTableCell align="right">
                  <span style={{ color: theme.palette.primary.main }}>
                    {product.percentage}%
                  </span>
                </StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        
        {!updatedData.length && (
          <Typography variant="body1" sx={{ p: 3, textAlign: 'center' }}>
            Aucune donnée disponible
          </Typography>
        )}
      </TableContainer>
    </Paper>
  );
};

export default ExcelTableFoodCost;