import React, { useEffect, useState } from "react";
import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer,
  LineChart, Line, AreaChart, Area
} from "recharts";
import { 
  Box, Typography, Grid, Paper, Skeleton, useTheme,
  Alert,  
} from "@mui/material";
import { ArrowUpward, ArrowDownward, TrendingUp } from "@mui/icons-material";
import Encaissement from "../Services/Encaisement";
import Decaissement from "../Services/Decaisement";

const chartColors = {
  encaissement: "#16a34a",
  decaissement: "#9333ea",
  netProfit: "#2563eb",
  prediction: "#f59e0b"
};

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload) {
    return (
      <Paper elevation={3} sx={{ padding: 2, borderRadius: 2 }}>
        <Typography variant="body2" color="text.secondary">{label}</Typography>
        {payload.map((entry) => (
          <Box key={entry.name} sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
            <Box sx={{
              width: 12,
              height: 12,
              backgroundColor: entry.color,
              mr: 1,
              borderRadius: '2px'
            }} />
            <Typography variant="body2">
              {entry.name}: {entry.value.toLocaleString('fr-TN', { minimumFractionDigits: 2 })} TND
            </Typography>
          </Box>
        ))}
      </Paper>
    );
  }
  return null;
};

const AnalyticsPage = () => {
  const theme = useTheme();
  const [data, setData] = useState([]);
  const [summary, setSummary] = useState({ 
    totalEncaissement: 0, 
    totalDecaissement: 0,
    netProfit: 0
  });
  const [predictedData, setPredictedData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const restaurantId = localStorage.getItem("restaurantId");

  useEffect(() => {
    if (!restaurantId) {
      setLoading(false);
      return;
    }

    const fetchData = async () => {
      try {
        const [encaissements, decaissements] = await Promise.all([
          Encaissement.getAllEncaissements(restaurantId),
          Decaissement.getAllDecaissements(restaurantId)
        ]);

        const combinedData = [];
        const allDates = [
          ...new Set([...encaissements.map(e => e.date), ...decaissements.map(d => d.date)])
        ];

        let totalEncaissement = 0;
        let totalDecaissement = 0;

        allDates.forEach(date => {
          const encValue = encaissements.find(e => e.date === date)?.value || 0;
          const decValue = decaissements.find(d => d.date === date)?.value || 0;

          totalEncaissement += encValue;
          totalDecaissement += decValue;

          combinedData.push({ 
            date,
            encaissement: encValue,
            decaissement: decValue,
            netProfit: encValue - decValue
          });
        });

        setData(combinedData);
        setSummary({
          totalEncaissement,
          totalDecaissement,
          netProfit: totalEncaissement - totalDecaissement
        });

        // Prediction logic
        if (combinedData.length > 0) {
          const lastMonthData = combinedData.slice(-30);
          const avgEnc = lastMonthData.reduce((sum, item) => sum + item.encaissement, 0) / 30;
          const avgDec = lastMonthData.reduce((sum, item) => sum + item.decaissement, 0) / 30;

          const predictedDataForNextMonth = Array.from({ length: 30 }, (_, i) => {
            const date = new Date();
            date.setDate(date.getDate() + i + 1);
            return {
              date: `${date.getDate()}/${date.getMonth() + 1}`,
              encaissement: avgEnc * (1 + i * 0.015),
              decaissement: avgDec * (1 + i * 0.01),
            };
          });
          setPredictedData(predictedDataForNextMonth);
        }

        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError(error.message);
        setLoading(false);
      }
    };

    fetchData();
  }, [restaurantId]);

  if (!restaurantId) {
    return <Alert severity="error" sx={{ m: 3 }}>Restaurant ID is missing. Please try again later.</Alert>;
  }

  if (error) {
    return <Alert severity="error" sx={{ m: 3 }}>{error}</Alert>;
  }

  return (
    <Box sx={{ p: { xs: 2, md: 4 }, backgroundColor: '#f8fafc' }}>
      <Typography variant="h4" sx={{ 
        mb: 4, 
        fontWeight: 700,
        color: theme.palette.mode === 'dark' ? '#fff' : '#1e293b'
      }}>
        Financial Analytics
      </Typography>

      {/* Summary Cards */}
      <Grid container spacing={3} sx={{ mb: 4 }}>
        {['totalEncaissement', 'totalDecaissement', 'netProfit'].map((metric, idx) => (
          <Grid item xs={12} md={4} key={metric}>
            <Paper sx={{
              p: 3,
              borderRadius: 3,
              background: theme.palette.mode === 'dark' 
                ? 'linear-gradient(195deg, #1a237e, #0d47a1)' 
                : `linear-gradient(195deg, ${idx === 0 ? '#059669' : idx === 1 ? '#6b21a8' : '#1d4ed8'}, ${idx === 0 ? '#10b981' : idx === 1 ? '#9333ea' : '#3b82f6'})`,
              color: '#fff',
              position: 'relative',
              overflow: 'hidden',
              '&:before': {
                content: '""',
                position: 'absolute',
                top: '-50px',
                right: '-30px',
                width: '100px',
                height: '100px',
                background: 'rgba(255,255,255,0.15)',
                borderRadius: '50%'
              }
            }}>
              {loading ? (
                <Skeleton variant="text" width="60%" height={40} />
              ) : (
                <>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5, mb: 1 }}>
                    {metric === 'totalEncaissement' && <ArrowUpward sx={{ fontSize: 28 }} />}
                    {metric === 'totalDecaissement' && <ArrowDownward sx={{ fontSize: 28 }} />}
                    {metric === 'netProfit' && <TrendingUp sx={{ fontSize: 28 }} />}
                    <Typography variant="h6" sx={{ fontWeight: 500 }}>
                      {metric.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}
                    </Typography>
                  </Box>
                  <Typography variant="h4" sx={{ fontWeight: 700 }}>
                    {summary[metric].toLocaleString('fr-TN', { minimumFractionDigits: 2 })} DT
                  </Typography>
                </>
              )}
            </Paper>
          </Grid>
        ))}
      </Grid>

      {/* Main Charts */}
      <Grid container spacing={3}>
        <Grid item xs={12} lg={8}>
          <Paper sx={{ p: 3, borderRadius: 3 }}>
            <Typography variant="h6" sx={{ mb: 2, fontWeight: 600 }}>
              Cash Flow Overview
            </Typography>
            {loading ? (
              <Skeleton variant="rectangular" height={400} />
            ) : (
              <ResponsiveContainer width="100%" height={400}>
                <AreaChart data={data}>
                  <CartesianGrid strokeDasharray="3 3" stroke={theme.palette.divider} />
                  <XAxis 
                    dataKey="date" 
                    tick={{ fill: theme.palette.text.secondary }}
                  />
                  <YAxis 
                    tickFormatter={value => value.toLocaleString('fr-TN')}
                    tick={{ fill: theme.palette.text.secondary }}
                  />
                  <Tooltip content={<CustomTooltip />} />
                  <Legend 
                    wrapperStyle={{ paddingTop: 20 }}
                    formatter={(value) => value.charAt(0).toUpperCase() + value.slice(1)}
                  />
                  <Area 
                    type="monotone" 
                    dataKey="encaissement" 
                    stroke={chartColors.encaissement} 
                    fill={chartColors.encaissement}
                    fillOpacity={0.2}
                    name="Encaissement"
                  />
                  <Area 
                    type="monotone" 
                    dataKey="decaissement" 
                    stroke={chartColors.decaissement} 
                    fill={chartColors.decaissement}
                    fillOpacity={0.2}
                    name="Décaissement"
                  />
                </AreaChart>
              </ResponsiveContainer>
            )}
          </Paper>
        </Grid>

        <Grid item xs={12} lg={4}>
          <Paper sx={{ p: 3, borderRadius: 3 }}>
            <Typography variant="h6" sx={{ mb: 2, fontWeight: 600 }}>
              Forecast for Next Month
            </Typography>
            {loading ? (
              <Skeleton variant="rectangular" height={400} />
            ) : (
              <ResponsiveContainer width="100%" height={400}>
                <LineChart data={predictedData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="date" />
                  <YAxis />
                  <Tooltip content={<CustomTooltip />} />
                  <Line 
                    type="monotone" 
                    dataKey="encaissement" 
                    stroke={chartColors.prediction} 
                    name="Predicted Encaissement"
                    dot={false}
                  />
                  <Line 
                    type="monotone" 
                    dataKey="decaissement" 
                    stroke={chartColors.prediction} 
                    name="Predicted Décaissement"
                    dot={false}
                  />
                </LineChart>
              </ResponsiveContainer>
            )}
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AnalyticsPage;
