import React, { useState, useEffect } from 'react';
import { 
  BarChart, Bar, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer 
} from 'recharts';
import { Grid, Card, Button, Typography, Box, CircularProgress, useTheme,MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import {
  AttachMoney, Receipt, AccountBalance, ShowChart,
  RestaurantMenu, BusinessCenter, People, AccountBalanceWallet,
  ShoppingCart, Warning, LibraryBooks, MoneyOff,TrendingDown,TrendingUp
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';
import { signOut } from 'firebase/auth';
import { onAuthStateChanged, getAuth } from "firebase/auth";
import { auth } from "../Firebase/Config";
import AddRestaurantFormPopup from '../Components/PopUpAddResto';
import { styled } from '@mui/system';
import RapportX from '../Services/RapportX';

// Custom styled components
const GradientCard = styled(Card)(({ theme }) => ({
  padding: theme.spacing(3),
  borderRadius: '16px',
  background: `linear-gradient(145deg, ${theme.palette.background.paper}, #f8f9ff)`,
  boxShadow: '0 8px 32px rgba(0,0,0,0.05)',
  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: '0 12px 48px rgba(0,0,0,0.1)'
  }
}));

const StatIconWrapper = styled('div')(({ theme, color }) => ({
  width: 48,
  height: 48,
  borderRadius: '12px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  background: `linear-gradient(135deg, ${color}30, ${color}10)`,
  color: color,
  marginRight: theme.spacing(2)
}));

// Chart color scheme
const chartColors = {
  primary: '#6366f1',
  secondary: '#10b981'
};

// Icon colors
const iconColors = {
  primary: '#6366f1',
  secondary: '#10b981',
  red: '#ef4444',
  amber: '#f59e0b',
  purple: '#8b5cf6',
  blue: '#3b82f6',
  pink: '#ec4899',
  teal: '#14b8a6',
  orange: '#f97316',
  slate: '#64748b',
  lime: '#84cc16',
  rose: '#f43f5e'
};

// Sample data for charts
const data = [
  { name: 'Jan', sales: 4000, profit: 2400 },
  { name: 'Feb', sales: 3000, profit: 1398 },
  { name: 'Mar', sales: 2000, profit: 9800 },
  { name: 'Apr', sales: 2780, profit: 3908 },
  { name: 'May', sales: 1890, profit: 4800 },
  { name: 'Jun', sales: 2390, profit: 3800 },
  { name: 'Jul', sales: 3490, profit: 4300 },
];



function Dashboard() {
  const theme = useTheme();
  const [rapportXValues, setRapportXValues] = useState({});
  const [totalRapportX, setTotalRapportX] = useState(0);
  const [restaurants, setRestaurants] = useState([]);
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const user = JSON.parse(localStorage.getItem("user"));
  const navigate = useNavigate();
  const restaurantId = localStorage.getItem("restaurantId");

  // Fetch RapportX values (mapped by date for current month)
  useEffect(() => {
    const fetchRapportX = async () => {
      try {
        const allRapportX = await RapportX.getAllRapportX(restaurantId);
        const now = new Date();
        const currentMonth = now.getMonth();
        const currentYear = now.getFullYear();
  
        // Filter items to only include those with dates in the current month and year
        const filteredRapportX = allRapportX.filter(item => {
          const itemDate = new Date(item.date);
          return (
            itemDate.getMonth() === currentMonth &&
            itemDate.getFullYear() === currentYear
          );
        });
  
        // Map the filtered items to an object with the date as key and the value as value
        const mappedValues = filteredRapportX.reduce((acc, item) => {
          acc[item.date] = item.value;
          return acc;
        }, {});
  
        setRapportXValues(mappedValues);
      } catch (error) {
        console.error("Error fetching RapportX:", error.message);
      }
    };
  
    fetchRapportX();
  }, [restaurantId]);
// State to hold the selected month and year
const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth()); // Default: current month
const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());

useEffect(() => {
  const fetchTotalRapportX = async () => {
    try {
      const allRapportX = await RapportX.getAllRapportX(restaurantId);

      // Filter items for the selected month/year
      const filteredRapportX = allRapportX.filter(item => {
        const itemDate = new Date(item.date);
        return (
          itemDate.getMonth() === selectedMonth &&
          itemDate.getFullYear() === selectedYear
        );
      });

      // Sum up the values
      const total = filteredRapportX.reduce((sum, item) => sum + Number(item.value), 0);

      setTotalRapportX(total);
    } catch (error) {
      console.error("Error fetching total RapportX:", error.message);
    }
  };

  if (restaurantId) {
    fetchTotalRapportX();
  }
}, [restaurantId, selectedMonth, selectedYear]); // Dependency updated



  // Fetch restaurants
  const fetchRestaurants = async () => {
    try {
      setLoading(true);
      const db = getFirestore();
      const auth = getAuth();
      const user = auth.currentUser;
      if (!user) throw new Error('User not logged in');
  
      const restaurantsRef = collection(db, 'restaurants');
      const q = query(restaurantsRef, where('ownerId', '==', user.uid));
      const querySnapshot = await getDocs(q);
      const restaurantData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
  
      setRestaurants(restaurantData);
  
      // Save the first restaurant's ID and name in localStorage
      if (restaurantData.length > 0) {
        localStorage.setItem('restaurantId', restaurantData[0].id);
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchRestaurants();
  }, []); // Run on initial load

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user && user.isVerifed === false) {
        navigate('/addResto');
      }
    });
    return unsubscribe;
  }, []);

  // Logout function
  const handleLogout = async () => {
    try {
      await signOut(auth);
      localStorage.removeItem('user');
      localStorage.removeItem('userToken');
      sessionStorage.removeItem('user');
      sessionStorage.removeItem('userToken');
      navigate('/');  
    } catch (error) {
      console.error("Logout failed: ", error.message);
    }
  };
  const totalHT = (totalRapportX / 1.07).toFixed(3); // Rounded to 3 decimal places
console.log(`${totalHT} TND`);


  return (
    <Box sx={{ p: 4, background: theme.palette.background.default }}>
      {/* Header Section */}
      <Box sx={{ mb: 6, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <div>
          <Typography variant="h4" fontWeight="700" gutterBottom>
            Welcome back, {user?.prenom}
          </Typography>
          <Typography variant="body1" color="text.secondary">
            Here's your business overview
          </Typography>
        </div>
        <AddRestaurantFormPopup />
      </Box>


      <div className="flex space-x-4 items-center">
  {/* Month Selector */}
  <FormControl variant="outlined" size="small">
    <InputLabel>Month</InputLabel>
    <Select
      value={selectedMonth}
      onChange={(e) => setSelectedMonth(Number(e.target.value))}
      label="Month"
    >
      {Array.from({ length: 12 }, (_, i) => (
        <MenuItem key={i} value={i}>
          {new Date(0, i).toLocaleString("default", { month: "long" })}
        </MenuItem>
      ))}
    </Select>
  </FormControl>

  {/* Year Selector */}
  <FormControl variant="outlined" size="small">
    <InputLabel>Year</InputLabel>
    <Select
      value={selectedYear}
      onChange={(e) => setSelectedYear(Number(e.target.value))}
      label="Year"
    >
      {Array.from({ length: 5 }, (_, i) => {
        const year = new Date().getFullYear() - i;
        return (
          <MenuItem key={year} value={year}>
            {year}
          </MenuItem>
        );
      })}
    </Select>
  </FormControl>
</div>
      {/* Statistics Grid */}
  
       

      <Grid container spacing={3} sx={{ mb: 6 }}>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <GradientCard>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <StatIconWrapper color={iconColors.primary}>
                <AttachMoney sx={{ fontSize: 'large' }} />
              </StatIconWrapper>
              <div>
                <Typography variant="body2" color="text.secondary">
                  Chiffre D'affaire TTC
                </Typography>
                <Typography variant="h5" fontWeight="600">
                  {totalRapportX} TND
                </Typography>
              </div>
            </Box>
          </GradientCard>
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={3}>
          <GradientCard>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <StatIconWrapper color={iconColors.secondary}>
                <Receipt sx={{ fontSize: 'large' }} />
              </StatIconWrapper>
              <div>
                <Typography variant="body2" color="text.secondary">
                  Chiffre D'affaire HTVA
                </Typography>
                <Typography variant="h5" fontWeight="600">
                  {totalHT} TND
                </Typography>
              </div>
            </Box>
          </GradientCard>
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={3}>
          <GradientCard>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <StatIconWrapper color={iconColors.red}>
                <AccountBalance sx={{ fontSize: 'large' }} />
              </StatIconWrapper>
              <div>
                <Typography variant="body2" color="text.secondary">
                  Chiffre D'affaire Réel TTC
                </Typography>
                <Typography variant="h5" fontWeight="600">
                  {totalHT} TND
                </Typography>
              </div>
            </Box>
          </GradientCard>
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={3}>
          <GradientCard>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <StatIconWrapper color={iconColors.amber}>
                <ShowChart sx={{ fontSize: 'large' }} />
              </StatIconWrapper>
              <div>
                <Typography variant="body2" color="text.secondary">
                  Marge Brut
                </Typography>
                <Typography variant="h5" fontWeight="600">
                  {totalHT} TND
                </Typography>
              </div>
            </Box>
          </GradientCard>
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={3}>
          <GradientCard>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <StatIconWrapper color={iconColors.purple}>
                <RestaurantMenu sx={{ fontSize: 'large' }} />
              </StatIconWrapper>
              <div>
                <Typography variant="body2" color="text.secondary">
                  Food Cost Global
                </Typography>
                <Typography variant="h5" fontWeight="600">
                  {totalHT} TND
                </Typography>
              </div>
            </Box>
          </GradientCard>
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={3}>
          <GradientCard>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <StatIconWrapper color={iconColors.blue}>
                <BusinessCenter sx={{ fontSize: 'large' }} />
              </StatIconWrapper>
              <div>
                <Typography variant="body2" color="text.secondary">
                  Cout Total d'exploitation
                </Typography>
                <Typography variant="h5" fontWeight="600">
                  {totalHT} TND
                </Typography>
              </div>
            </Box>
          </GradientCard>
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={3}>
          <GradientCard>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <StatIconWrapper color={iconColors.pink}>
                <People sx={{ fontSize: 'large' }} />
              </StatIconWrapper>
              <div>
                <Typography variant="body2" color="text.secondary">
                  Charge Salairiale
                </Typography>
                <Typography variant="h5" fontWeight="600">
                  {totalHT} TND
                </Typography>
              </div>
            </Box>
          </GradientCard>
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={3}>
          <GradientCard>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <StatIconWrapper color={iconColors.teal}>
                <AccountBalanceWallet sx={{ fontSize: 'large' }} />
              </StatIconWrapper>
              <div>
                <Typography variant="body2" color="text.secondary">
                  Total Accompte
                </Typography>
                <Typography variant="h5" fontWeight="600">
                  {totalHT} TND
                </Typography>
              </div>
            </Box>
          </GradientCard>
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={3}>
          <GradientCard>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <StatIconWrapper color={iconColors.orange}>
                <ShoppingCart sx={{ fontSize: 'large' }} />
              </StatIconWrapper>
              <div>
                <Typography variant="body2" color="text.secondary">
                  Total Des Achat
                </Typography>
                <Typography variant="h5" fontWeight="600">
                  {totalHT} TND
                </Typography>
              </div>
            </Box>
          </GradientCard>
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={3}>
          <GradientCard>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <StatIconWrapper color={iconColors.slate}>
                <Warning sx={{ fontSize: 'large' }} />
              </StatIconWrapper>
              <div>
                <Typography variant="body2" color="text.secondary">
                  Total Des Impayés
                </Typography>
                <Typography variant="h5" fontWeight="600">
                  {totalHT} TND
                </Typography>
              </div>
            </Box>
          </GradientCard>
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={3}>
          <GradientCard>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <StatIconWrapper color={iconColors.lime}>
                <LibraryBooks sx={{ fontSize: 'large' }} />
              </StatIconWrapper>
              <div>
                <Typography variant="body2" color="text.secondary">
                  TVA Collecté
                </Typography>
                <Typography variant="h5" fontWeight="600">
                  {totalHT} TND
                </Typography>
              </div>
            </Box>
          </GradientCard>
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={3}>
          <GradientCard>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <StatIconWrapper color={iconColors.rose}>
                <MoneyOff sx={{ fontSize: 'large' }} />
              </StatIconWrapper>
              <div>
                <Typography variant="body2" color="text.secondary">
                  TVA Decuctible
                </Typography>
                <Typography variant="h5" fontWeight="600">
                  {totalHT} TND
                </Typography>
              </div>
            </Box>
          </GradientCard>
        </Grid>
      </Grid>

      {/* Charts Section */}
      <Grid container spacing={4} sx={{ mb: 6 }}>
        <Grid item xs={12} md={6}>
          <GradientCard>
            <Typography variant="h6" gutterBottom sx={{ 
              display: 'flex', alignItems: 'center',
              color: chartColors.primary
            }}>
              <TrendingUp sx={{ mr: 1 }} /> Sales Overview
            </Typography>
            <ResponsiveContainer width="100%" height={300}>
              <BarChart data={data}>
                <CartesianGrid strokeDasharray="3 3" vertical={false} />
                <XAxis dataKey="name" tick={{ fill: theme.palette.text.secondary }} />
                <YAxis tick={{ fill: theme.palette.text.secondary }} />
                <Tooltip 
                  contentStyle={{
                    background: theme.palette.background.paper,
                    border: 'none',
                    borderRadius: '8px',
                    boxShadow: theme.shadows[3]
                  }}
                />
                <Bar dataKey="sales" fill={chartColors.primary} radius={[4, 4, 0, 0]} />
              </BarChart>
            </ResponsiveContainer>
          </GradientCard>
        </Grid>

        <Grid item xs={12} md={6}>
          <GradientCard>
            <Typography variant="h6" gutterBottom sx={{ 
              display: 'flex', alignItems: 'center',
              color: chartColors.secondary
            }}>
              <TrendingDown sx={{ mr: 1 }} /> Profit Trend
            </Typography>
            <ResponsiveContainer width="100%" height={300}>
              <LineChart data={data}>
                <CartesianGrid strokeDasharray="3 3" vertical={false} />
                <XAxis dataKey="name" tick={{ fill: theme.palette.text.secondary }} />
                <YAxis tick={{ fill: theme.palette.text.secondary }} />
                <Tooltip 
                  contentStyle={{
                    background: theme.palette.background.paper,
                    border: 'none',
                    borderRadius: '8px',
                    boxShadow: theme.shadows[3]
                  }}
                />
                <Line 
                  type="monotone" 
                  dataKey="profit" 
                  stroke={chartColors.secondary}
                  strokeWidth={2}
                  dot={{ fill: chartColors.secondary }}
                />
              </LineChart>
            </ResponsiveContainer>
          </GradientCard>
        </Grid>
      </Grid>

      {/* Restaurants Section */}
      <Typography variant="h5" fontWeight="600" gutterBottom>
        Your Restaurants
      </Typography>
      <Grid container spacing={3} sx={{ mb: 6 }}>
        {restaurants.map((restaurant) => (
          <Grid item xs={12} sm={6} md={4} key={restaurant.id}>
            <GradientCard sx={{
              backgroundImage: 'linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5))',
              color: 'white',
              minHeight: 200,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-end',
              position: 'relative',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              '&:before': {
                content: '""',
                position: 'absolute',
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                background: `url(${restaurant.image || '/default-restaurant.jpg'})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                borderRadius: '16px',
                zIndex: -1
              }
            }}>
              <Typography variant="h6" fontWeight="600">
                {restaurant.name}
              </Typography>
              <Typography variant="body2">
                {restaurant.address || 'No address available'}
              </Typography>
              <Typography variant="body2">
                {restaurant.phone || 'No phone available'}
              </Typography>
            </GradientCard>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default Dashboard;
